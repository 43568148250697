import { FileWordOutlined, FolderOutlined, FolderFilled, FilePdfOutlined, FileExcelOutlined, FilePptOutlined, FileOutlined, YoutubeOutlined } from '@ant-design/icons';
import classes from './HelpersStyles.module.scss';

export const documnentIcons = {
  docx: <FileWordOutlined className={classes.icon_word} />,
  doc: <FileWordOutlined className={classes.icon_word} />,
  xlsx: <FileExcelOutlined className={classes.icon_excel} />,
  xls: <FileExcelOutlined className={classes.icon_excel} />,
  pptx: <FilePptOutlined className={classes.icon_powerPoint} />,
  ppt: <FilePptOutlined className={classes.icon_powerPoint} />,
  pdf: <FilePdfOutlined className={classes.icon_pdf} />,
  youtube: <YoutubeOutlined className={classes.icon_youtube} />,
  folder: <FolderOutlined className={classes.icon_folder} />,
  rootfolder: <FolderFilled className={classes.icon_folder} />,
  default: <FileOutlined className={classes.icon_word}/>
};

export const availableIconFormat = Object.keys(documnentIcons);

