import {
  DeleteFilled,
  FileExcelOutlined,
  FolderAddOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UnlockOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Dropdown, Input, MenuProps, Popconfirm, Tooltip } from 'antd';
import api from 'api';
import axios from 'axios';
import cn from 'classnames';
import { IFolder } from 'interfaces';
import IDocument, { DocumentsType, ViewType } from 'interfaces/IDocument';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  setCurrentFolder,
  setDocuments,
  setDocumentsType,
  setIsLoadingDocuments,
  setSelectedDocument,
  setSelectedFolder,
  setViewType,
} from 'store/slices/dataDocumentsSlice';
import { setIsFolderCreateOpen } from 'store/slices/dataRoomSlice';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import { useDebounce } from 'use-debounce';
import ManagementLoader from '../Sceletons/ManagementLoader';
import classes from './DocumentManagement.module.scss';
import { MenuItemType } from 'antd/es/menu/interface';

const cacheDocumentsBeforeSearch: { documents: IDocument[]; folders: IFolder[] } = {
  documents: [],
  folders: [],
};

const cleanCashStorage = () => {
  cacheDocumentsBeforeSearch.documents = [];
  cacheDocumentsBeforeSearch.folders = [];
};

interface DocumentManagementProps {
  setIsVisibleUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenPermissionsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEmptyText: React.Dispatch<React.SetStateAction<string>>;
  handleDownload: (is_webview: boolean) => void;
  handleDeleteChoosenObjects: () => void;
  reloadSelected: () => void;
  currentFolderWithPermissions: any;
}

export default function DocumentManagement({
  setIsVisibleUploadModal,
  setIsOpenPermissionsModal,
  handleDownload,
  handleDeleteChoosenObjects,
  reloadSelected,
  setEmptyText,
  currentFolderWithPermissions,
}: DocumentManagementProps) {
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { choosenDocuments, isLoadingDocuments, documents, folders, currentFolder } = useAppSelector(
    (store) => store.documents
  );
  const { dataRoom, isLoadingRooms } = useAppSelector((store) => store.dataRoom);
  const { userPermissions, isLoadingUserData, isLoadingUserPermissions } = useAppSelector((store) => store.userData);
  const { isUploadingDocuments, isDeletingDocuments, isDownloadingDocuments } = useAppSelector(
    (store) => store.windowState
  );

  const isAvailablePermissionsSettings = userPermissions?.can_edit_folder_permissions;
  const isAvailableCreateFolder = userPermissions?.can_create_folder;
  const isAvailableUploading =
    currentFolderWithPermissions?.permissions && currentFolderWithPermissions?.permissions?.includes('can_upload');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 750);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      cleanCashStorage();
    };
  }, []);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    if (!cacheDocumentsBeforeSearch.documents.length && !cacheDocumentsBeforeSearch.folders.length) {
      cacheDocumentsBeforeSearch.documents = documents;
      cacheDocumentsBeforeSearch.folders = folders;
    }
    if (debouncedSearchTerm && debouncedSearchTerm?.trim()) {
      dispatch(setIsLoadingDocuments(true));
      api
        .getDocuments({ room_id: dataRoom?.id!, search: debouncedSearchTerm, action: 'view' }, source.token)
        .then((response) => {
          dispatch(setDocuments({ documents: response.data, folders: [] }));
          !response.data.length && setEmptyText('emptySearch');
        })
        .finally(() => {
          dispatch(setIsLoadingDocuments(false));
        });
    } else if (debouncedSearchTerm === '' && debouncedSearchTerm?.trim() === '') {
      dispatch(
        setDocuments({
          documents: cacheDocumentsBeforeSearch.documents,
          folders: cacheDocumentsBeforeSearch.folders,
        })
      );
      cleanCashStorage();
    }

    return () => {
      setEmptyText('fallback');
      source.cancel();
    };
  }, [debouncedSearchTerm]);

  const breadcrumbItems = React.useMemo(() => {
    const onNavigate = (idx: number) => {
      const slicedFolders = currentFolder.slice(0, idx);
      localStorage.setItem('previousFolderPath', JSON.stringify(slicedFolders));
      reloadSelected();
      dispatch(setSelectedDocument(null));
      dispatch(setSelectedFolder(null));
      dispatch(setMoreInfoHidden(true));
      dispatch(setCurrentFolder(slicedFolders));
      navigate(`/room/${dataRoom?.id}/documents/${slicedFolders[slicedFolders.length - 1]?.id || ''}`);
    };

    const breadcrumbs = [{ name: '' }, ...currentFolder].map((folder, idx) => {
      const name = idx ? folder?.name : t('Documents.management.rootFolder');

      return {
        title: (
          <div className={classes.entityName} onClick={() => onNavigate(idx)}>
            <Tooltip placement='bottomLeft' mouseEnterDelay={1} title={name}>
              {name}
            </Tooltip>
          </div>
        ),
      };
    });

    return breadcrumbs;
  }, [currentFolder, dataRoom, i18n.language]);

  const { choosenFolders, choosenFiles, selectedDocuments } = React.useMemo(() => {
    const choosenFiles = choosenDocuments.filter(({ key }) => !(key as string).startsWith('folder'));
    const choosenFolders = choosenDocuments.filter(({ key }) => (key as string).startsWith('folder'));
    const selectedDocuments = choosenDocuments.map(({ permissions }) => permissions);

    return { choosenFiles, choosenFolders, selectedDocuments };
  }, [choosenDocuments]);

  const { isSomeChoosenDocumentsForbiddenToDelete, downloadDocumentsStatus } = React.useMemo(() => {
    let downloadDocumentsStatus: 'download' | 'downloadAsPdf' | 'downloadAsDoc' | 'disabled' = 'disabled';

    const downloadCascadePermissionCases = [
      ['can_delete', 'can_sharing'],
      ['can_upload', 'can_download_pdf'],
      ['can_view', 'can_delete', 'can_download_pdf'],
    ];

    const canDownloadCases = selectedDocuments.map((documentPermissions) =>
      downloadCascadePermissionCases
        .map((perms) => perms.every((el) => documentPermissions?.includes(el)))
        .includes(true)
    );

    const isCanDownload = !canDownloadCases.includes(false);
    const isSomeChoosenDocumentsForbiddenToDownload = selectedDocuments
      .map((documentPermissions) => documentPermissions?.includes('can_download'))
      .includes(false);
    const isSomeChoosenDocumentsForbiddenToDelete = selectedDocuments
      .map((documentPermissions) => documentPermissions?.includes('can_delete'))
      .includes(false);
    const isSomeChoosenDocumentsForbiddenToDownloadPDF = selectedDocuments
      .map((documentPermissions) => documentPermissions?.includes('can_download_pdf'))
      .includes(false);

    if (
      !isSomeChoosenDocumentsForbiddenToDownloadPDF &&
      (!isSomeChoosenDocumentsForbiddenToDownload || isCanDownload)
    ) {
      downloadDocumentsStatus = 'download';
    } else if (!isSomeChoosenDocumentsForbiddenToDownloadPDF || !isSomeChoosenDocumentsForbiddenToDownload) {
      downloadDocumentsStatus = !isSomeChoosenDocumentsForbiddenToDownloadPDF ? 'downloadAsPdf' : 'downloadAsDoc';
    } else if (isCanDownload) {
      downloadDocumentsStatus = 'downloadAsDoc';
    } else {
      downloadDocumentsStatus = 'disabled';
    }

    choosenDocuments.forEach((el) => {
      const id = (el.key as string).split(' ')[2];
      const signedEl = documents.find((doc) => doc.id === id);
      if (signedEl?.signing_status !== null && (!isSomeChoosenDocumentsForbiddenToDownload || isCanDownload))
        downloadDocumentsStatus = 'downloadAsDoc';
    });

    return {
      isSomeChoosenDocumentsForbiddenToDownload,
      isSomeChoosenDocumentsForbiddenToDelete,
      isSomeChoosenDocumentsForbiddenToDownloadPDF,
      downloadDocumentsStatus,
    };
  }, [selectedDocuments]);

  const isDisabledDeleteButton =
    (choosenFiles.length === 0 && choosenFolders.length === 0) ||
    isSomeChoosenDocumentsForbiddenToDelete ||
    isDeletingDocuments;

  const namesOfChoosenFiles = React.useMemo(
    () => choosenFiles.map(({ key }) => documents.find((doc) => doc.id === (key as string).split(' ')[2])?.name),
    [choosenFiles, documents]
  );

  const namesOfChoosenFolders = React.useMemo(
    () => choosenFolders.map(({ key }) => folders.find((folder) => folder.id === (key as string).split(' ')[1])?.name),
    [choosenFolders, folders]
  );

  const onUploadClick: React.MouseEventHandler<HTMLElement> = (event) => {
    const name =
      (event.target as HTMLButtonElement).name ||
      ((event.target as HTMLButtonElement).parentElement as HTMLButtonElement).name;

    dispatch(setDocumentsType(name as DocumentsType));
    setIsVisibleUploadModal(true);
  };

  const onCreateFolderClick = () => {
    dispatch(setIsFolderCreateOpen(true));
  };

  const handlePdfDownload = () => handleDownload(true);
  const handleDocumentDownload = () => handleDownload(false);
  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value);

  const onPopoverClick = (type: ViewType) => {
    localStorage.setItem('viewType', type);
    dispatch(setViewType(type as ViewType));
  };

  const onDeleteClick = () => setIsOpenDelete(true);
  const onDeleteCancel = () => setIsOpenDelete(false);
  const onDeleteSubmit = () => {
    handleDeleteChoosenObjects();
    onDeleteCancel();
  };

  const content = (
    <div>
      <div onClick={() => onPopoverClick('List')} className={classes.popover_item}>
        {t('Documents.management.list')}
      </div>
      <div onClick={() => onPopoverClick('Large')} className={classes.popover_item}>
        {t('Documents.management.large')}
      </div>
    </div>
  );

  const uploadItems: MenuProps['items'] = [
    {
      label: (
        <Button
          onClick={onUploadClick}
          loading={isUploadingDocuments}
          className={cn(classes.button, classes.grey)}
          icon={<UploadOutlined />}
          type='default'
          name='files'
        >
          {t('Documents.management.uploadFiles')}
        </Button>
      ),
      key: 0,
    },

    {
      label: (
        <Button
          onClick={onUploadClick}
          loading={isUploadingDocuments}
          className={cn(classes.button, classes.grey)}
          icon={<UploadOutlined />}
          type='default'
          name='confidentialFiles'
        >
          {t('Documents.management.uploadConfidentialFiles')}
        </Button>
      ),
      key: 1,
    },
  ];

  const downloadItems: MenuProps['items'] = [
    {
      label: (
        <Button
          onClick={handleDocumentDownload}
          className={cn(classes.button, classes.grey)}
          icon={<FileExcelOutlined className={classes.grey} />}
          type='default'
          loading={isDownloadingDocuments}
          disabled={choosenFiles.length === 0 || isLoadingDocuments || isDownloadingDocuments}
        >
          {t('Documents.management.downloadAsDoc')}
        </Button>
      ),
      key: 0,
    },

    {
      label: (
        <Button
          onClick={handlePdfDownload}
          className={cn(classes.button, classes.grey)}
          icon={<FileExcelOutlined className={classes.grey} />}
          type='default'
          loading={isDownloadingDocuments}
          disabled={choosenFiles.length === 0 || isLoadingDocuments || isDownloadingDocuments}
        >
          {t('Documents.management.downloadAsPdf')}
        </Button>
      ),
      key: 1,
    },
  ];

  const downloadButtonCases = {
    download: (
      <Dropdown
        menu={{ items: downloadItems }}
        disabled={choosenFiles.length === 0 || isLoadingDocuments || isDownloadingDocuments}
        trigger={['click']}
      >
        <Button
          className={cn(classes.button, classes.grey)}
          icon={<FileExcelOutlined className={classes.grey} />}
          type='default'
          loading={isDownloadingDocuments}
        >
          {t('Documents.management.download')}
        </Button>
      </Dropdown>
    ),
    downloadAsDoc: (downloadItems[0] as MenuItemType).label,
    downloadAsPdf: (downloadItems[1] as MenuItemType).label,
    disabled: (
      <Button
        className={cn(classes.button, classes.grey)}
        icon={<FileExcelOutlined className={classes.grey} />}
        type='default'
        loading={isDownloadingDocuments}
        disabled
      >
        {t('Documents.management.download')}
      </Button>
    ),
  };

  return (
    <div className={classes.managementArea}>
      {isLoadingRooms || isLoadingUserData || isLoadingUserPermissions ? (
        <ManagementLoader />
      ) : (
        <div className={classes.managementWrap}>
          {dataRoom ? (
            <>
              <div className={classes.managementBlock}>
                <div className={classes.managementBlockTitle}>
                  {debouncedSearchTerm ? (
                    t('Documents.management.searchMode')
                  ) : (
                    <Breadcrumb items={breadcrumbItems} separator=' > ' />
                  )}

                  <Input
                    className={classes.managementSearch}
                    placeholder={t('Documents.management.search')}
                    onChange={onSearchChange}
                    prefix={<SearchOutlined style={{ color: 'lightgrey', fontSize: 16 }} />}
                    allowClear
                    disabled={isLoadingUserPermissions || isLoadingRooms}
                  />
                </div>
              </div>

              <div className={classes.managementButtons}>
                <div className={classes.managementMainButtons}>
                  {dataRoom.agreement_file ? (
                    <Dropdown menu={{ items: uploadItems }} disabled={!isAvailableUploading} trigger={['click']}>
                      <Button
                        loading={isUploadingDocuments}
                        className={classes.button}
                        icon={<UploadOutlined />}
                        type='primary'
                      >
                        {t('Documents.management.upload')}
                      </Button>
                    </Dropdown>
                  ) : (
                    <Button
                      onClick={onUploadClick}
                      loading={isUploadingDocuments}
                      className={classes.button}
                      disabled={!isAvailableUploading}
                      icon={<UploadOutlined />}
                      type='primary'
                      name='files'
                    >
                      {t('Documents.management.uploadFiles')}
                    </Button>
                  )}

                  {isAvailablePermissionsSettings && (
                    <Button
                      className={classes.button}
                      icon={<UnlockOutlined />}
                      onClick={() => setIsOpenPermissionsModal(true)}
                      type='primary'
                    >
                      {t('Documents.management.permissions')}
                    </Button>
                  )}

                  {isAvailableCreateFolder && (
                    <Button
                      className={cn(classes.button, classes.grey)}
                      icon={<FolderAddOutlined className={classes.grey} />}
                      type='default'
                      onClick={onCreateFolderClick}
                    >
                      {t('Documents.management.newFolder')}
                    </Button>
                  )}

                  {downloadButtonCases[downloadDocumentsStatus]}
                </div>

                <div className={classes.RGroupButtons}>
                  <Popconfirm
                    title={t('Documents.confirm.delete')}
                    description={`${t('Documents.confirm.confirmDelete')}: ${[
                      ...namesOfChoosenFiles,
                      ...namesOfChoosenFolders,
                    ].join(', ')}?`}
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    okButtonProps={{ loading: isDeletingDocuments, danger: true }}
                    cancelText={t('Documents.confirm.cancel')}
                    okText={t('Documents.confirm.delete')}
                    onConfirm={onDeleteSubmit}
                    onCancel={onDeleteCancel}
                    open={isOpenDelete}
                    placement='topRight'
                  >
                    <Button
                      className={cn(
                        isDisabledDeleteButton ? classes.button : classes.buttonRed,
                        isDisabledDeleteButton ? classes.grey : classes.red
                      )}
                      type='default'
                      disabled={isDisabledDeleteButton}
                      loading={isDeletingDocuments}
                      onClick={onDeleteClick}
                      icon={
                        <DeleteFilled
                          className={
                            (choosenFiles.length === 0 && choosenFolders.length === 0) ||
                            isSomeChoosenDocumentsForbiddenToDelete
                              ? classes.grey
                              : classes.red
                          }
                          style={{ fontSize: 18 }}
                        />
                      }
                    />
                  </Popconfirm>

                  {/* ПЕРЕКЛЮЧЕНИЕ РЕЖИМА ОТОБРАЖЕНИЯ ОБЬЕКТОВ */}
                  {/* <Popover content={content} title={t('Documents.management.viewType')} trigger="hover">
                  <Button
                    className={cn(classes.button, classes.grey)}
                    icon={<EllipsisOutlined className={classes.grey}/>}
                    type='default'
                  >
                  </Button>
                </Popover> */}
                </div>
              </div>
            </>
          ) : (
            <div className={classes.emptyRooms}>{t('Documents.management.noAvailable')}</div>
          )}
        </div>
      )}
    </div>
  );
}
