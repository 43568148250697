import { Segmented } from 'antd';
import { CreateChatModal } from 'components/Modals';
import RowLoader from 'components/Sceletons/RowLoader';
import { membersType } from 'interfaces';
import Mark from 'mark.js';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { createChat, createGLobalChat, fetchChatHistory } from 'store/reducers/chatCreator';
import { setIsOpenCreateModal, setListStatus, setSelectedChatRoom } from 'store/slices/chatsSlice';
import classes from './ChatTab.module.scss';
import ChatTabControls from './ChatTabControls';
import ChatTabItem from './ChatTabItem';

function ChatTab() {
  const [currentSegment, setCurrentSegment] = useState<'main' | 'room'>('main');
  const [searchValue, setSearchValue] = useState<string>('');

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const { globalUsers } = useAppSelector((state) => state.dataUsers);
  const {
    roomAllChats,
    chatData,
    selectedChat,
    isLoadedSession,
    roomChatLoading,
    globalChats,
    isOpenCreateModal,
    listStatus,
  } = useAppSelector((state) => state.chat);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoadingChat = !Boolean(isLoadedSession && !roomChatLoading) // && !globalChatLoading);
  const isDisabledSegments = isLoadingChat || !dataRoom?.id;
  const onCreateModalOpen = () => dispatch(setIsOpenCreateModal(true));

  const pathTypes = [
    `/room/${dataRoom?.custom_url || dataRoom?.id}/chat/document/`,
    `/room/${dataRoom?.custom_url || dataRoom?.id}/chat/`,
    `/room/${dataRoom?.custom_url || dataRoom?.id}/chat/user/`,
  ];
  const actionCases = {
    main: {
      action: createGLobalChat,
      route: () => '/global-chats/',
    },
    room: {
      action: createChat,
      route: (type: number) => pathTypes[type],
    },
  };

  useEffect(() => {
    const pathList = pathname.split('/');
    !pathList.includes('global-chats') && setCurrentSegment('room');
  }, []);

  const markText = (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => {
    const markInstance = new Mark(markerRef.current as HTMLElement);

    setTimeout(() => {
      markInstance.unmark({
        className: classes.mark,
        done: () => {
          markInstance.mark(text, {
            className: classes.mark,
          });
        },
      });
    }, timeout || 100);
  };

  const onCancelCreateModal = () => {
    dispatch(setListStatus(currentSegment));
    dispatch(setIsOpenCreateModal(false));
  };

  const onClickItem = async (member: membersType) => {
    const data = {
      members_ids: [userData?.id!, member.id],
      entity_type: 2,
      room_id: dataRoom?.id!,
      title: member.email,
    };

    onCancelCreateModal();
    const { payload } = (await dispatch(actionCases[currentSegment].action({ data, ownerId: userData?.id! }))) as any;
    navigate(
      actionCases[currentSegment].route(payload.chat?.entity_type) +
        (payload.chat?.entity_type === 1 ? '' : payload.chat.id)
    );
  };

  const onChangeSegment = (value: any) => {
    const segmentValue = value as keyof typeof chatCases;
    setCurrentSegment(segmentValue);
    dispatch(setListStatus(segmentValue));
  };

  const { localUesrlist, globalUserList } = useMemo(() => {
    const localUesrlist = dataRoom?.available_users?.map(({ last_name, first_name, id, email }) => ({
      name: `${first_name} ${last_name}`,
      email,
      id,
    }));

    const globalUserList = globalUsers.map(({ last_name, first_name, id, email }) => ({
      name: `${first_name} ${last_name}`,
      email,
      id,
    }));

    return { globalUserList, localUesrlist };
  }, [dataRoom, globalUsers]);

  const segmentOptions = [
    {
      label: 'Комната',
      value: 'room',
    },
    {
      label: 'Личные',
      value: 'main',
    },
  ];

  const chatCases = {
    main: globalChats,
    room: roomAllChats,
  };

  const listCases = {
    main: globalUserList,
    room: localUesrlist,
    groupList: selectedChat?.members.map((member) => member),
  };

  const filteredChats = useMemo(() => {
    return chatCases[currentSegment as keyof typeof chatCases].filter((chat) =>
      `${t(`Chats.types.${chat.entity_type}`)} ${chat.entity_name || chat.title} ${
        chat.entity_type === 2 && chat.title
      }`
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
  }, [searchValue, globalChats, roomAllChats, currentSegment]);

  return (
    <div className={classes.chatTab}>
      <Segmented
        className={classes.segment}
        options={segmentOptions}
        value={currentSegment}
        disabled={isDisabledSegments}
        onChange={onChangeSegment}
      />
      <ChatTabControls
        t={t}
        searchValue={searchValue}
        isLaoding={isLoadingChat}
        setSearchValue={setSearchValue}
        onCreateModalOpen={onCreateModalOpen}
      />

      {isLoadingChat ? (
        <div style={{ marginRight: 10 }}>
          <RowLoader width={600} padding={'0 0 0 10px'} height={70} borderradius={5} />
        </div>
      ) : (
        <div className={classes.chatTab_tabs}>
          {!chatCases[currentSegment as keyof typeof chatCases].length ? (
            <div>У вас не существуют чатов ни с кем. Создайте чат через кнопку "+"</div>
          ) : (
            filteredChats.map((chat) => {
              const onTabClick = async () => {
                dispatch(setSelectedChatRoom(chat));
                if (!chatData[chat.id] || !chatData[chat.id].isLoadedHistory)
                  await dispatch(fetchChatHistory({ chat_id: chat.id, room_id: chat?.room_id! }));
                navigate(
                  actionCases[currentSegment].route(chat?.entity_type) + (chat?.entity_type === 1 ? '' : chat.id)
                );
              };

              return (
                <ChatTabItem
                  t={t}
                  key={chat.id}
                  onTabClick={onTabClick}
                  markText={markText}
                  searchValue={searchValue}
                  selectedChat={selectedChat!}
                  chat={chat}
                />
              );
            })
          )}
        </div>
      )}

      {isOpenCreateModal && (
        <Suspense fallback={<div />}>
          <CreateChatModal
            t={t}
            members={listCases[listStatus]!}
            ownerId={userData?.id!}
            onClickItem={onClickItem}
            open={isOpenCreateModal}
            onCancel={onCancelCreateModal}
            title={t('Chats.modals.create.title')}
          />
        </Suspense>
      )}
    </div>
  );
}

export default ChatTab;
