import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Typography, Divider, Form, Input, Button, message, Select } from 'antd';
import { MailFilled, LockFilled } from '@ant-design/icons';
import { deleteCookie, setCookie } from 'helpers/cookies';
import { ILoginData } from 'interfaces';
import { AxiosError } from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { getBrowserClientData } from 'helpers/browserData/browserData';
import auth from 'api/auth';
import api from 'api';
import classes from './Login.module.scss';
import { TFAuthModal } from 'components/Modals';

const { Link, Paragraph, Title } = Typography;

type LoginErrorType = {
  detail: string;
};

export default function Login() {
  // const [currentRandomString, setCurrentRandomString] = React.useState(randomString())
  const [isLaoding, setIsLoading] = React.useState<boolean>(false);
  const [isOpenTfa, setIsOpenTfa] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)

  const navigate = useNavigate();
  const defaultLanguage = localStorage.getItem('language') || 'en';

  // const scanQrCode = async (signal: AbortSignal) => {
  //   try {
  //     const {data: response, status} = await auth.qrResponce(currentRandomString, signal);
  //     if (status === 202) {
  //       scanQrCode(signal);
  //     } else if (status === 200) {
  //       setCookie('athena-token', response.access_token, 1);
  //       navigate('/');
  //     }
  //   } catch (e) {
  //     console.log(e, 'scanQrCode error')
  //   }
  // }

  // React.useEffect(() => {
  //   const controller = new AbortController();
  //   scanQrCode(controller.signal);
  //   return () => {
  //     controller.abort()
  //   }
  // }, []);

  const onCancelTfa = () => setIsOpenTfa(false);

  const loginHandle = async (values: ILoginData) => {
    setIsLoading(true);
    try {
      setEmail(values.email)
      deleteCookie('athena-token');
      deleteCookie('refresh_token');

      let browserData: any = null;
      try {
        browserData = await getBrowserClientData();
      } catch (error) {
        console.log(error, 'error while collecting browser information');
      };

      const response = await auth.login({
        ...values,
        email: values.email.toLocaleLowerCase(),
        details: {
          email: values.email.toLocaleLowerCase(),
        },
      });

      if(response.data.message){
        message.success(t('login.modals.verify.sendCode'));
        setIsOpenTfa(true);
        return;
      }

      setCookie('athena-token', response.data.access_token, 1);
      setCookie('refresh_token', response.data.refresh_token, 1);
      const form_data = new FormData();
      
      form_data.append('browser_data', JSON.stringify(browserData));
      message.destroy();

      if (isMobile) navigate('/rooms')
      else navigate('/');

      browserData && await api.sendBrowserFingerprint(form_data);
    } catch (err) {
      const error = err as AxiosError<LoginErrorType>;
      message.error(error.response?.data.detail ? t(`login.${error.response?.data.detail}`) : t('login.someError'), 15);
    } finally {
      setIsLoading(false);
    }
  };

  const { i18n, t } = useTranslation();

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const languagesValues = [
    {
      value: 'en',
      label: <Trans i18nKey='languages.en' />,
    },
    {
      value: 'ru',
      label: <Trans i18nKey='languages.ru' />,
    },
    {
      value: 'kg',
      label: <Trans i18nKey='languages.kg' />,
    },
    {
      value: 'es',
      label: <Trans i18nKey='languages.es' />,
    },
  ];

  // const currentLink = () => {
  //   return `http://qrcoder.ru/code/?${currentRandomString}&4&0`
  // }

  return (
    <div className={classes.mainWrap}>
      {/* <div className={classes.image}>
        <Title level={3} className={classes.image_title}>
        TazaDeal
        </Title>
      </div> */}
      <Layout className={classes.loginElem}>
        <Select
          className={classes.localizationSelect}
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        <div className={classes.loginCard}>
          <Typography>
            <Title level={2}>{t('login.login')}</Title>
            <Paragraph className={classes.subTitle}>{t('login.info')}</Paragraph>
          </Typography>
          <Form className={classes.loginForm} onFinish={loginHandle}>
            <Form.Item name={'email'} rules={[{ required: true, message: t('login.inputEmail'), type: 'email' }]}>
              <Input
                placeholder={t('login.email')}
                size='large'
                prefix={<MailFilled style={{ fontSize: '90%' }} />}
                className={classes.input}
              />
            </Form.Item>
            <Form.Item name={'password'} rules={[{ required: true, message: t('login.inputPassword') }]}>
              <Input.Password
                placeholder={t('login.password')}
                size='large'
                prefix={<LockFilled />}
                className={classes.input}
              />
            </Form.Item>
            {/* Необходим механизм восстановления пароля */}
            {/* <div className={classes.passwordSubtitle}>
              <Link href='/signup'>{t('login.forgot')}</Link>
            </div> */}
            <Button htmlType='submit' loading={isLaoding}>
              {t('login.logIn')}
            </Button>
          </Form>
          <Divider />

          {/* Убран qr код для регистрации */}
          {/* <div className={classes.qrcode}>
            <img src={currentLink()} alt='QR код' width='148' height='148' title='QR код'/>
          </div>
          <Divider /> */}

          <Typography>
            {/* {t('login.havenAcc')}{' '}
            <Link href='/signup' className={classes.primary}>
             {t('login.signup')}
            </Link><br /> */}
            {t('login.forgot')}{' '}
            <Link href='/reset' className={classes.primary}>
              {t('resetPassword.reset')}
            </Link>
          </Typography>
        </div>
      </Layout>

      {isOpenTfa && (
        <React.Suspense fallback={<div />}>
          <TFAuthModal email={email} isOpen t={t} onCancel={onCancelTfa} />
        </React.Suspense>
      )}
    </div>
  );
}
