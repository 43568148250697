import React from 'react';
import classes from '../Viewer.module.scss';
import { CloseCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';


type VideoViewerProps = {
  url: any;
  onClose?: () => void;
  downloadDocument?: () => void;
}

const VideoViewer = ({url, onClose, downloadDocument}: VideoViewerProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.videoViewer}>
      { onClose && <CloseCircleOutlined className={classes.close} onClick={onClose} /> }
      { downloadDocument &&
        <Button onClick={downloadDocument} className={classes.download} icon={<DownloadOutlined />}>
          {t('templink.download')}
        </Button>
      }
      <ReactPlayer playing controls width='100%' height='100%' url={url} />
    </div>
  );
}

export default VideoViewer;
