import { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import classNames from 'classnames';
import classes from '../Messanger.module.scss';

type Props = {
  date: string;
  isFirst: boolean;
  username: string;
  message: string;
};

const RecieveMessage: FC<Props> = ({ date, isFirst, username, message }) => {
  return (
    <div className={classNames(classes.messanger_recieve, isFirst && classes.messanger_first)}>
      {isFirst && <Avatar size={30} icon={<UserOutlined />} className={classes.messanger_recieveAvatar} />}
      <div className={classNames(classes.messanger_recieveMessage, isFirst && classes.messanger_first)}>
        <div className={classes.messanger_userData}>
          <span className={classes.messanger_userName}>{username}</span>
          {/* <span className={classes.messanger_userPosition}>Engineering</span> */}
        </div>
        <p className={classes.messanger_messageText}>{message}</p>
        <p className={classes.messanger_messageTime}>
          <span>{date}</span>
        </p>
      </div>
    </div>
  );
};

export default RecieveMessage;
