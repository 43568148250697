import translations from './translation.json';
import Documents from './Documents.json';
import Users from './Users.json';
import Rooms from './Rooms.json';
import Report from './Report.json';
import Settings from './Settings.json';
import Billing from './Billing.json';
import Organizations from './Organizations.json';
import Chats from './Chats.json';
import Confirm from './Confirm.json';
import Header from './Header.json';
import Sides from './Sides.json';
import Logs from './Logs.json';
import FAQ from './FAQ.json';

const en = {
  ...translations,
  Documents,
  Logs,
  Users,
  Report,
  Rooms,
  Settings,
  Billing,
  Organizations,
  Chats,
  Confirm,
  Header,
  Sides,
  FAQ,
};

export default en;
