import React from 'react';
import classes from '../SingingTab.module.scss'
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';

type ActionButtonsProps = {
  isSigningCreated: boolean;
  isPasswordSended: boolean;
  setIsOpenConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  isAddingSgining: boolean;
  usersId: string[];
  sendRequestForApproval: () => Promise<void>;
  getСonfirmationPassword: () => Promise<void>;
  loadingProcess: { sendPass: boolean; decline: boolean; delete: boolean; };
  deleteSigning: () => Promise<void>;
  onOpenDeclineModal: () => void;
}

const ActionButtons = ({
  isSigningCreated,
  isPasswordSended,
  setIsOpenConfirmModal,
  isAddingSgining,
  usersId,
  sendRequestForApproval,
  getСonfirmationPassword,
  loadingProcess,
  deleteSigning,
  onOpenDeclineModal
}: ActionButtonsProps) => {
  const { needSigningFromUsers } = useAppSelector((store) => store.signing);

  const { t } = useTranslation();

  const enterPasswordButton = (
    <Button
      className={classes.sendForSigning}
      type='primary'
      onClick={() => setIsOpenConfirmModal(true)}
    >
      {t('Documents.details.signing.enterPassword')}
    </Button>
  );

  const isDisabledDelete = React.useMemo(() => {
    const usersWhoParticipated = needSigningFromUsers.filter(user => user.signing_status !== null);    
    return Boolean(usersWhoParticipated.length);
  }, [needSigningFromUsers]);  

  const actionButtons = (
    <div className={classes.actionButtons}>
      <Button
        type='primary'
        loading={loadingProcess.sendPass}
        onClick={getСonfirmationPassword} 
      >
        {t('Documents.details.signing.signButton')}
      </Button>

      <Button
        className={classes.cancelButton}
        type='primary'
        loading={loadingProcess.decline}
        onClick={onOpenDeclineModal}
      >
        {t('Documents.details.signing.declineButton')}
      </Button>

      <Button
        danger
        loading={loadingProcess.delete}
        onClick={deleteSigning}
        disabled={isDisabledDelete}
      >
        {t('Documents.details.signing.deleteButton')}
      </Button>
    </div>
  );

  const sendSignState = (
    <Button
      className={classes.sendForSigning}
      type='primary'
      disabled={usersId.length < 1}
      loading={isAddingSgining}
      onClick={sendRequestForApproval}
    >
      {t('Documents.details.signing.sendButton')}
    </Button>
  );

  return (
    <div>
      {
        isSigningCreated
        ? ( isPasswordSended
          ? enterPasswordButton
          : actionButtons
        )
        : sendSignState
      }
    </div>
  );
}

export default ActionButtons;
