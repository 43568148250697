import { FileFilled, LockFilled, SettingFilled, SaveOutlined, EditOutlined } from '@ant-design/icons';
import { faBriefcase, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, InputRef, message, Segmented, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './HeaderComponent.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import RowLoader from 'components/Sceletons/RowLoader';
import api from 'api';
import dayjs from 'dayjs';
import { setRooms } from 'store/slices/userDataSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { createSettingLog } from 'routes/Settings/createSettingLog';


function Navigation() {
  const [roomName, setRoomName] = React.useState('');
  const [editingMode, setEditingMode] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [isChanging, setIsChanging] = React.useState(false);
  const inputRef = React.useRef<InputRef>(null);

  const { selectedKeys } = useAppSelector((state) => state.windowState);
  const { dataRoom, isLoadingRooms } = useAppSelector((state) => state.dataRoom);
  const { userData, userPermissions, isLoadingUserData } = useAppSelector((store) => store.userData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    if (editingMode) inputRef.current?.focus();
  }, [editingMode]);

  React.useEffect(() => {
    dataRoom?.name && setRoomName(dataRoom?.name);
    setEditingMode(false);
  },[dataRoom]);

  const values = useMemo(() => {
    return dataRoom?.id
      ? [
          {
            label: (
              <Tooltip title={t('sidebar.documents')}>
                <FileFilled className={classes.iconMenu} />
              </Tooltip>
            ),
            value: `documents`,
          },
          // {
          //   label: (
          //     <Tooltip title={t('sidebar.sides')}>
          //       <FontAwesomeIcon className={classes.iconMenu} icon={faBriefcase} />
          //     </Tooltip>
          //   ),
          //   value: `sides`,
          // },
        ]
      : [];
  }, [dataRoom, i18n.language]);

  userPermissions?.can_edit_room_settings && values.push({
    label: (
      <Tooltip title={t('sidebar.settings')}>
        <SettingFilled className={classes.iconMenu} />
      </Tooltip>
    ),
    value: `settings`,
  });

  userPermissions?.can_view_logs && values.push({
    label: (
      <Tooltip title={t('sidebar.logs')}>
        <FontAwesomeIcon className={classes.iconMenu} icon={faRectangleList} />
      </Tooltip>
    ),
    value: `logs`,
  });
  
  dataRoom?.agreement_enable && values.push({
    label: (
      <Tooltip title={t('sidebar.nda')}>
        <LockFilled className={classes.iconMenu} />
      </Tooltip>
    ),
    value: `nda`,
  });

  const onSegmentCahnge = (value: string | number) => {
    const selectedValue = value as string;
    dispatch(setSelectedKeys([selectedValue]));
    if (selectedValue === 'documents') {
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/documents`);
      else if (userData?.available_rooms[0]?.id)
        navigate(`/room/${userData?.available_rooms[0]?.custom_url || userData?.available_rooms[0]?.id}/documents`);
      else navigate(`/noAvailablesRooms`);
    } else {
      if (dataRoom?.id) navigate(`/room/${dataRoom.custom_url || dataRoom?.id}/${selectedValue}`);
      else if (userData?.available_rooms[0]?.id)
        navigate(`/room/${userData?.available_rooms[0]?.custom_url || userData?.available_rooms[0]?.id}/${selectedValue}`);
    }
  };

  const isPrivateRoom = dataRoom?.id === '143d9fd4-96b7-46e8-819f-9515a04eaff9' // не трогать

  const addonWidth = 50;    

  const onChangeRoomName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value.length) {
      setRoomName(e.target.value);
      setWidth(e.target.value.length * 10 + addonWidth);
    } else {
      setEditingMode(false);
      dataRoom?.name && setRoomName(dataRoom?.name);
    }
  };
  
  const startEditing = () => {
    dataRoom?.name && setWidth(addonWidth + dataRoom?.name.length * 10);
    setEditingMode(true);
  };

  const returnFromEditing = () => {
    setRoomName(dataRoom?.name!);
    setEditingMode(false);
  };

  const changeRoomName = async () => {
    setIsChanging(true);
    try {
      const formDataSettings = new FormData() as any;
      formDataSettings.append('name', roomName);
      formDataSettings.append('details', JSON.stringify(createSettingLog(dataRoom!, {...dataRoom!, name: roomName})));

      const response = await api.setRoomSettings(
        formDataSettings,
        dataRoom?.id!,
        'update',
        ''
      );

      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const newRooms = userData?.available_rooms.map((room) =>
        room.id === response.data.id
          ? {
            ...response.data,
            created_at: dayjs(response.data.created_at).unix() * 1000,
            last_action_timestamp: UTCDate,
            permissions: room.permissions,
          }
          : room
      ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);

      dispatch(setRooms(newRooms || []));
      dispatch(setDataRoom({...dataRoom!, name: roomName}));

      message.success(
        `${t('Settings.success.settingsUploaded.before')} ${response.data?.name}
        ${t('Settings.success.settingsUploaded.after')}`
      );
    } catch(e) {
      message.error(
        `${t('Settings.error.settingsUpload.before')} ${dataRoom?.name} 
        ${t('Settings.error.settingsUpload.after')}`
      );
    } finally {
      setIsChanging(false)
    }
  }

  const buttonSave = (
    <Tooltip title={t('sidebar.saveChanges')}>
      <Button
        icon={<SaveOutlined className={classes.saveButton}/>}
        type='link'
        loading={isChanging}
        size='small'
        onClick={changeRoomName}
      />
    </Tooltip>
  );  

  return (
    <div className={classes.navigation}>
      <div className={classes.navigation_title}>
        {editingMode
          ? (
            <Input
              className={classes.changeInput}
              style={{width: width}}
              ref={inputRef}
              value={roomName}
              onChange={onChangeRoomName}
              allowClear
              onClear={returnFromEditing}
              variant={'borderless'}
              addonAfter={roomName !== dataRoom?.name ? buttonSave : <span/>}
            />
          )
          : (
            <div className={classes.blockName}>
              <Tooltip title={dataRoom?.name} placement='bottomLeft' overlayStyle={{maxWidth: 'calc(100vw - 400px)'}}>
                {dataRoom?.name}  
              </Tooltip>
              <Button
                className={classes.editButton}
                icon={<EditOutlined className={classes.editIcon}/>}
                type='link'
                size='small'
                onClick={startEditing}
              />
            </div>
          )
        }

      </div>
      {isPrivateRoom && <a rel='noreferrer' target='_blank' href='https://usquestlaw.com' className={classes.navigation_logo} />}
      <div className={classes.navigation_icons}>
        {(isLoadingRooms || isLoadingUserData)
          ? <RowLoader width='200px' padding='2px 0 0 0' height='27px' />
          : <Segmented className={classes.navigation_segment} options={values} selected value={selectedKeys[0]} onChange={onSegmentCahnge} />
        }
      </div>
    </div>
  );
}

export default Navigation;
